<template>
  <div class="body-whatsapp">
    <section class="main-container pt-5 pb-5">
      <div class="container top-container">
        <div class="content">
          <div class="container" v-if="bannerTop">
            <!-- Banner -->

            <div
              v-for="(banner, key) in bannerTop"
              :key="key"
              class="mt-2 mb-2"
            >
              <a :href="banner.target_url" target="_blank"
                ><img style="width: 100%" :src="banner.path"
              /></a>
            </div>
          </div>
          <div class="container">
            <div class="title-bar text-center" style="margin: 0; padding: 10px">
              <div class="title margin-right-auto" style="font-size: 30px">
                Contact Us
              </div>
            </div>
            <div class="card-matches card-bg-blue">
              <div class="card-matches-title"></div>
              <div
                class="card-matches-content"
                style="overflow: auto; font-size: 20px"
              >
                <a target="_blank" :href="whatsappLink">
                  <span style="display: inline-block; color: white"
                    >Join our Whatsapp for more information</span
                  >&nbsp; &nbsp;
                  <img
                    src="../assets/img/whatsapp_green.png"
                    alt=""
                    style="width: 40px; height: 40px; display: inline-block"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Whatsapp",
  props: {
    msg: String,
  },
  data() {
    return {
      matchSetting: "",
      bannerHost: "",
      bannerSettings: [
        {
          title: "pc top",
          category: 1,
          status: 1,
          target_url: "https://978bet36.com/",
          cat_title: "电脑-顶部",
          path: "https://72963.com/uploads/images/20201214/5696d802b494e7f762dac27e22f08513.gif",
          width: 970,
          height: 150,
        },
        {
          title: "72963-pc",
          category: 1,
          status: 1,
          target_url: "https://77577.com",
          cat_title: "电脑-顶部",
          path: "https://72963.com/uploads/images/20201214/banner-77577-desktop.png",
          width: 970,
          height: 150,
        },
      ],
    };
  },
  computed: {
    bannerTop() {
      if (this.bannerSettings != null) {
        return this.bannerSettings.filter(function (item) {
          return item.category == 1;
          //return item;
        });
      } //

      return "";
    },
    whatsappLink() {
      var text = "https://wa.me/";
      var number_locale = this.$i18n.locale == "vi" ? "_vi" : "";
      var number =
        this.matchSetting &&
        this.matchSetting["whatsapp_number" + number_locale]
          ? this.matchSetting["whatsapp_number" + number_locale]
          : "";
      return text + number;
    },
  },
  created() {
    axios
      .get(`${this.bannerHost}/api/banner`)
      .then((response) => {
        const axiosBannerList = response.data.data;
        this.bannerSettings = axiosBannerList;
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  },
  mounted() {
    //Api match detail

    axios
      .get(`/api/setting-detail`)
      .then((response) => {
        this.matchSetting = response.data.data;
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  },
};
</script>

<style scope>
</style>
